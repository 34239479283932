import { Button, Image, message, Modal, Tooltip } from 'antd';
import row from 'antd/es/row';
import { action, observable } from 'mobx';
import React from 'react';
import type { CommonData, DictData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { RenderByPermission, request, SearchListModal } from '../../utils';
import QrcodeEditorModel from './modal/placementEdit/model';

export interface IPlacementAd {
  /**
   * 创建时间
   */
  createName?: string;
  /**
   * 结束时间
   */
  endTime?: Date;
  /**
   * 投放位置
   */
  location?: number;
  /**
   * 开始时间
   */
  startTime?: Date;
  /**
   * 状态
   */
  status?: number;
  /**
   * 标题
   */
  title?: string;
  /**
   * 类型
   */
  type?: number;
  /**
   * id
   */
  id: string;
  /**
   * 投放图片
   */
  images?: string[];
}

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.getDictOptions('ad_type');
    this.getDictOptions('ad_location');
    this.pageStore.grid.onQuery();
  }

  public editStore = new QrcodeEditorModel({ parent: this });

  @observable public adTypeOptions = []; //广告类型列表

  @observable public adLocationOptions = []; //投放位置列表

  @observable public statusOptions = [
    // 状态列表
    {
      label: '未开始',
      value: '0',
    },
    {
      label: '已启用',
      value: '1',
    },
    {
      label: '已禁用',
      value: '2',
    },
    {
      label: '已过期',
      value: '3',
    },
  ];

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'title',
        label: '名称',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'type',
        label: '类型',
      },
      {
        type: 'select',
        field: 'status',
        label: '状态',
        data: this.statusOptions,
      },
      {
        type: 'input',
        field: 'createName',
        label: '创建人',
      },
      {
        type: 'select',
        field: 'location',
        label: '投放位置',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '投放时间',
        format: 'YYYY-MM-DD HH:mm:ss',
        formatParams: 'YYYY-MM-DD HH:mm:ss',
        span: 6,
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        type: 'primary',
        permissionId: '2',
        text: '新增',
        handleClick: () => {
          this.editStore.onShow();
        },
      },
    ],
    grid: {
      rowHeight: 100,
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  {row.status === 0 && (
                    <Button
                      onClick={() => {
                        this.editStore.onShow(row);
                      }}
                      type="link"
                    >
                      编辑
                    </Button>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_12`}>
                  {row.status !== 0 && row.status !== 3 && (
                    <Button
                      onClick={() => {
                        this.handleEnable(row);
                      }}
                      type="link"
                    >
                      {row.status === 1 ? '禁用' : '启用'}
                    </Button>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  {row.status === 0 && (
                    <Button
                      onClick={() => this.deleteItem(row)}
                      type="link"
                    >
                      删除
                    </Button>
                  )}
                </RenderByPermission>
              </>
            );
          },
        },
        {
          key: 'title',
          name: '名称',
          formatter: ({ row }) =>
            row?.title?.length > 10 ? (
              <Tooltip title={row.title}>
                <span className="cursor-pointer">{`${row.title.substring(0, 10)}...`}</span>
              </Tooltip>
            ) : (
              row?.title
            ),
        },
        {
          key: 'typeName',
          name: '类型',
        },
        {
          key: 'locationName',
          name: '投放位置',
        },
        {
          key: 'startTime',
          name: '有效时间',
          width: 300,
          formatter: ({ row }) => `${row?.startTime}至${row?.endTime}`,
        },
        {
          key: 'qrCode',
          name: '投放图片',
          formatter: ({ row }) =>
            row?.images?.length > 0 ? (
              <div className="h-full flex items-center">
                <Image
                  src={row.images[0]}
                  width={80}
                  height={80}
                  className="object-cover rounded-[4px]"
                />
              </div>
            ) : null,
        },
        {
          key: 'createName',
          name: '创建人',
        },
        {
          key: 'createTime',
          name: '创建时间',
        },
        {
          key: 'status',
          name: '状态',
          formatter: ({ row }) => this.statusOptions.find((item) => item.value === String(row?.status))?.label || '',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showSelectedTotal: false,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<CommonData<IPlacementAd[]>>({
          url: '/quan/back/ad/list',
          method: 'POST',
          data: { ...postParams },
        }).then((res) => res);
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 删除
  public deleteItem = (row: IPlacementAd) => {
    confirm({
      title: `删除提示`,
      content: '是否删除该数据?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: '/quan/back/ad/delete',
          method: 'POST',
          data: { ids: row.id },
        });
        message.success('删除成功');
        this.pageStore?.grid?.onQuery();
      },
    });
  };

  public handleEnable = (row: IPlacementAd) => {
    confirm({
      title: `提示`,
      content: `是否${row.status === 1 ? '禁用' : '启用'}该数据?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: '/quan/back/ad/operation',
          method: 'POST',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1,
          },
        });
        message.success('操作成功');
        this.pageStore?.grid?.onQuery();
      },
    });
  };

  // 查询字典数据
  @action
  public getDictOptions = async (dictType: string) => {
    const req = await request<CommonData<DictData[]>>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
    switch (dictType) {
      case 'ad_type': {
        this.adTypeOptions = data;
        this.pageStore.programme.filterItems.addDict({ type: data });
        break;
      }
      case 'ad_location': {
        this.adLocationOptions = data;
        this.pageStore.programme.filterItems.addDict({ location: data });
        break;
      }
    }
  };
}
