import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './index.less';

interface Props {
  readonly radius?: number;
  readonly children?: React.ReactNode;
  readonly onDelte?: () => void;
}

// 给img提供删除遮罩
const ImgDelte: React.FC<Props> = (props) => {
  const { children, radius = 8, onDelte } = props;
  return (
    <div
      className={styles.imageBox}
      style={{ borderRadius: radius }}
    >
      {children ? children : null}
      <div className={styles.markBox}>
        <DeleteOutlined
          className={styles.deleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onDelte?.();
          }}
        />
      </div>
    </div>
  );
};

export default ImgDelte;
