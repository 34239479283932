import type { FormInstance } from 'antd';
import { message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import type { CommonData } from '../../../../utils';
import { request } from '../../../../utils';
import type { IPlacementAd } from '../../model';

export default class PlacementEditorModel {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public visible = false;

  @observable public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public parent = null;

  @observable public uploadLoading = false;

  @observable public submitType = 'add';

  @observable public editId = '';

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (row?: IPlacementAd) => {
    this.visible = true;
    if (row?.id) {
      this.submitType = 'edit';
      this.editId = row.id;
      setTimeout(() => {
        this.ref.setFieldsValue({
          ...row,
          type: String(row.type),
          location: String(row.location),
          times: [dayjs(row.startTime), dayjs(row.endTime)],
          image: row?.images[0],
        });
      }, 0);
    }
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.submitType = 'add';
    this.editId = '';
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    const values = this.ref.getFieldsValue();
    if (!values.times[0].isBefore(values.times[1])) {
      message.warning('开始时间不能大于结束时间');
      return;
    }
    this.saveLoading = true;
    const params = {
      ...values,
      startTime: values.times[0].format('YYYY-MM-DD HH:mm:ss'),
      endTime: values.times[1].format('YYYY-MM-DD HH:mm:ss'),
      images: [values.image],
    };
    const url = this.submitType === 'add' ? '/quan/back/ad/add' : '/quan/back/ad/edit';
    request<CommonData<any>>({
      url,
      method: 'POST',
      data: {
        ...params,
        id: this.editId,
      },
    })
      .then((result) => {
        message.success(result.msg || '操作成功');
        this.ref.resetFields();
        this.onClose();
        this.parent.pageStore?.grid?.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
