import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { message, Upload } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { singleUploadObs } from '../../utils';
import compressImgOrVideo from '../../utils/compress';
import ImgDelte from '../imgDelete';
import css from './index.module.less';

interface Props {
  readonly accept?: string;
  readonly obsPath?: string;
  readonly sizeLimit?: number;
}

interface SingleUploadProps extends Props {
  readonly file: string;
  readonly onChange?: (url: string) => void;
}

interface MultipleUploadProps extends Props {
  fileList: string[];
  readonly maxCount?: number;
  readonly onChange?: (urls: string[]) => void;
}

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const defaultAccept = '.png,.PNG,.jpg,.JPG,.jpeg,.JPEG';

const beforeUpload = (file: FileType, sizeLimit: number) => {
  const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('仅支持上传JPG、PNG!');
    return false;
  }
  const _sizeLimit = file.size / 1024 / 1024 < sizeLimit;
  if (!_sizeLimit) {
    message.error(`图片必须小于${sizeLimit}MB!`);
    return false;
  }
  return isJpgOrPng && _sizeLimit;
};

/**
 * 单个上传图片
 */
export const SingleUploadImage: React.FC<SingleUploadProps> = (props) => {
  const { obsPath = '', sizeLimit = 5, accept = defaultAccept, file, onChange = () => {} } = props;
  const [uploadLoading, setUploadLoading] = React.useState(false);

  return (
    <div className={css.uploadBox}>
      <Upload
        listType="picture-card"
        maxCount={1}
        showUploadList={false}
        accept={accept}
        disabled={uploadLoading}
        beforeUpload={(file) => beforeUpload(file, sizeLimit)}
        customRequest={async (i: any) => {
          try {
            const unit = i.file.name.split(',');
            const file: any = await compressImgOrVideo(i.file);
            if (file === false) {
              return;
            }
            setUploadLoading(true);
            const url = await singleUploadObs(file, `${obsPath}${i.file.uid}${unit[unit.length - 1]}`);
            setUploadLoading(false);
            if (url) {
              onChange(url);
            }
          } catch {
            setUploadLoading(false);
          }
        }}
      >
        {file && typeof file === 'string' ? (
          <ImgDelte onDelte={() => onChange('')}>
            <img
              src={file}
              alt="sharePic"
              className="w-full h-full object-cover rounded-[8px]"
            />
          </ImgDelte>
        ) : (
          <button
            type="button"
            className="border-none bg-none"
          >
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传</div>
          </button>
        )}
      </Upload>
    </div>
  );
};

/**
 * 多个上传图片
 */
export const MultipleUploadImage: React.FC<MultipleUploadProps> = observer((props) => {
  const { fileList, obsPath = '', accept = defaultAccept, sizeLimit = 300, maxCount = 8, onChange = () => {} } = props;
  const [newFileList, setNewFileList] = useState<string[]>(fileList.slice());
  const [uploadLoading, setUploadLoading] = React.useState(false);

  const setAddFileList = (url: string) => {
    setNewFileList([...newFileList, url]);
  };

  const setDeleteFileList = (url: string) => {
    setNewFileList(newFileList.filter((item: any) => item !== url));
    onChange(newFileList);
  };

  return (
    <div className="flex">
      <div className="flex flex-wrap">
        {newFileList?.length
          ? newFileList.map((item, index) => (
              <div
                className="w-[102px] h-[102px] mr-[10px] mt-[10px]"
                key={`file-${index.toString()}`}
              >
                <ImgDelte onDelte={() => setDeleteFileList(item)}>
                  <img
                    src={item}
                    alt="sharePic"
                    className="w-full h-full object-cover rounded-[8px] flex-shrink-0"
                  />
                </ImgDelte>
              </div>
            ))
          : null}
        <Upload
          className="mt-[10px]"
          listType="picture-card"
          fileList={[]}
          accept={accept}
          beforeUpload={(file) => beforeUpload(file, sizeLimit)}
          maxCount={maxCount}
          customRequest={async (i: any) => {
            try {
              const unit = i.file.name.split(',');
              const file: any = await compressImgOrVideo(i.file);
              if (file === false) {
                return;
              }
              setUploadLoading(true);
              const url = await singleUploadObs(file, `${obsPath}${i.file.uid}${unit[unit.length - 1]}`);
              setUploadLoading(false);
              setAddFileList(url);
            } catch {
              setUploadLoading(false);
            }
          }}
          onRemove={(values) => {
            setDeleteFileList(values.url);
          }}
        >
          {newFileList && newFileList.length >= maxCount ? null : (
            <button
              style={{
                border: 0,
                background: 'none',
              }}
              type="button"
            >
              {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="mt-[8px]">{uploadLoading ? '上传中' : '上传'}</div>
            </button>
          )}
        </Upload>
      </div>
    </div>
  );
});
