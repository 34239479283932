import type { GetProps } from 'antd';
import { DatePicker, Form, Input, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import { SingleUploadImage } from '../../../../compnents/upload';
import type Model from './model';

const OBS_PATH = 'placement/image/';

const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// 禁止选择过去的日期
const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().startOf('day');
// 禁止选择过去的时间-起始时间为当前时间的10分钟后,只处理当天的数据
const disabledRangeTime: RangePickerProps['disabledTime'] = (time) => {
  const now = dayjs();
  const isToDay = now.isSame(time, 'day');
  let startHour = now.hour();
  let startMinute = now.minute() + 10;

  if (startMinute >= 60) {
    startHour += 1;
    startMinute -= 60;
  }

  return {
    disabledHours: () => range(0, 24).filter((hour) => hour < startHour && isToDay),
    disabledMinutes: (hour: number) => {
      if (hour === startHour) {
        return range(0, 60).filter((minute) => minute < startMinute && isToDay);
      }
      return [];
    },
    disabledSeconds: () => range(0, 60).filter((second) => second < now.second() && isToDay),
  };
};

const PlacementEdit = observer(({ store }: { store: Model }): React.ReactElement => {
  if (!store) {
    return null;
  }
  const { visible, parent, saveLoading, submitType, onSave, onClose, setRef } = store;

  return (
    <Modal
      centered
      onOk={onSave}
      onClose={onClose}
      confirmLoading={saveLoading}
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title={submitType === 'add' ? '新增' : '编辑'}
    >
      <Form
        ref={setRef}
        labelCol={{ span: 4 }}
      >
        <Form.Item
          label="名称"
          name="title"
          rules={[{ required: true }]}
          validateTrigger="onBlur"
        >
          <Input
            style={{ width: '100%' }}
            autoComplete="off"
            placeholder="请输入"
            maxLength={50}
            showCount
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="类型"
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: '请选择类型',
            },
          ]}
        >
          <Select
            options={parent.adTypeOptions}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          name="location"
          label="投放位置"
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: '请选择投放位置',
            },
          ]}
        >
          <Select
            options={parent.adLocationOptions}
            placeholder="请选择"
          />
        </Form.Item>
        <Form.Item
          label="有效时间"
          className="mb-0"
          rules={[{ required: true }]}
          shouldUpdate
        >
          {({ getFieldValue, setFieldValue }) => {
            const times = getFieldValue('times');
            return (
              <Form.Item
                name="times"
                getValueFromEvent={() => times}
                rules={[
                  {
                    required: true,
                    message: '请选择有效时间',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <RangePicker
                  onChange={(e) => {
                    setFieldValue('times', e);
                  }}
                  onOk={(e) => {
                    setFieldValue('times', e);
                  }}
                  className="w-full"
                  disabledDate={disabledDate}
                  disabledTime={disabledRangeTime}
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="投放图片"
          shouldUpdate
          rules={[{ required: true }]}
          extra="最大允许上传大小10M以内，建议尺寸：305*458 （1：1.5）"
        >
          {({ getFieldValue, setFieldValue }) => {
            const image = getFieldValue('image');
            return (
              <Form.Item
                getValueFromEvent={() => image}
                name="image"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: '请上传投放图片',
                  },
                ]}
              >
                <SingleUploadImage
                  obsPath={OBS_PATH}
                  sizeLimit={10}
                  file={image}
                  onChange={(url) => setFieldValue('image', url)}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          label="跳转地址"
          name="jumpUrl"
          extra="只支持小程序内部地址"
        >
          <Input
            placeholder="请输入"
            maxLength={100}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default PlacementEdit;
